import React, { useEffect } from "react";
import Header from "./Header/Header";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "./Footer/Footer";

const Layout = ({ children }) => {
 
  useEffect(() => {
    document.body.classList.remove("menu-open");
    gsap.registerPlugin(ScrollTrigger);
    gsap.utils.toArray(".fade-ani").forEach(function (box) {
      ScrollTrigger.create({
        trigger: box,
        toggleActions: "play none none none",
        once: true,
        onEnter: function () {
          gsap.fromTo(
            box,
            { autoAlpha: 0, y: 90 },
            { duration: 1, autoAlpha: 1, y: 0 }
          );
        },
      });
    });
  }, []);
  return (
    <div className="global-wrapper">
      <Header />
      <main>{children}</main>
      <div className="hidden my-40 lg:text-28 text-22 max-w-515 inner-page-banner pb-0 mt-0 mt-30 lg:mt-60 margint-0 paddingb-0 my-30 lg:my-60"></div>
    </div>
  );
};
export default Layout;
