import * as React from "react";
import Layout from "../components/layout-404";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo/Seo";

const NotFoundPage = () => {
  return (
    <>
    <Layout>
    <Seo />
     <section className="hero-section inner-page-banner guest-page">
      <div className="h-100 w-100 min-h-700 relative"> 
        <img className="h-100pr w-100 object-cover" src="../images/404.jpg" />
        <div className="absolute top-0 left-0 right-0 bottom-0 flex align-middle items-center justify-center p-15">
          <div className="text-center content-text">
            <div className="fade-ani">
              <h1 className="text-white mb-15 mx-auto">404</h1>
              <p>It seems the page you are looking for no longer exists. Visit our homepage or use our navigation bar to find what you’re looking for.</p>
              <Link to="/" className="btn mt-30 dark-btn cursor-pointer uppercase font-400 tracking-006em large-btn">Go back to home</Link>
            </div>
          </div>
        </div>
        <div className="absolute text-white left-0 right-0 bottom-50 banner-bottom-menu fade-ani">
          <ul className="flex uppercase text-13 w-100 flex-1 justify-center gap-25">
            <li><span>unmated</span></li>
            <li><span>untouched</span></li>
            <li><span>unforgettable</span> </li>
          </ul>
        </div>
      </div>
      </section>
      </Layout>
    </>
  );
};

export default NotFoundPage;
